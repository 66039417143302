<template>
  <div class="container global-list-main-container">
    <div class="general-balance-all-graph-container">
      <div
        class="global-lists-filters-container special-margin-top"
        id="filter-container"
      >
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="global-lists-filters-container" id="filter-container">
        <b-field label="Filtrar por almacen" label-position="inside">
          <b-select
            placeholder="Filtrar por almacen"
            icon="warehouse"
            v-model="filterWarehouse"
            @input="(value) => changeDateFilterValue()"
          >
            <option value="">Todos los almacenes</option>
            <option
              v-for="warehouse in warehouses"
              :value="warehouse"
              :key="warehouse.NOMBRE_ALM"
            >
              {{ warehouse.NOMBRE_ALM }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="text-center">
        Filtros disponibles para esta sección Fecha de inicio, Fecha de fin y
        Almacen
      </div>
      <hr class="analytics-divider" />
      <div class="initial-lists-container special-margin-top">
        <div class="initial-single-list-full">
          <p class="initial-table-name">
            Total de ventas y compras por personal
            <span class="global-form-hint-color">(antes de impuestos)</span>
          </p>
          <b-table
            :data="salesPurchasesPersonal"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPage"
            :per-page="perPage"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay ventas o compras</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in salesPurchasesPersonalColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
              >
                <p v-if="column.money && !column.percentage">
                  {{ props.row[column.field] | money("MXN", 2) }}
                </p>
                <p v-if="!column.money && !column.percentage">
                  {{ props.row[column.field] }}
                </p>
                <p v-if="!column.money && column.percentage">
                  {{ props.row[column.field] ? props.row[column.field] : 0 }}%
                </p>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>

      <div class="initial-lists-container special-margin-top">
        <div class="initial-single-list-full">
          <p class="initial-table-name">
            Total de ventas y compras por el personal asignado a gerentes
            <span class="global-form-hint-color">(antes de impuestos)</span>
          </p>
          <b-table
            :data="salesPurchasesManagers"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageManagers"
            :per-page="perPageManagers"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay ventas o compras</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in salesPurchasesManagersColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
              >
                <p v-if="column.money && !column.percentage">
                  {{ props.row[column.field] | money("MXN", 2) }}
                </p>
                <p v-if="!column.money && !column.percentage">
                  {{ props.row[column.field] }}
                </p>
                <p v-if="!column.money && column.percentage">
                  {{ props.row[column.field] ? props.row[column.field] : 0 }}%
                </p>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { round } from "../../../utils/fns";

// @ is an alias to /src
export default {
  name: "AnalyticsPersonal",
  components: {},
  data() {
    return {
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      filterWarehouse: "",
      filterCategory: "",
      filterSubCategory: "",
      salesPurchasesPersonal: [],
      salesPurchasesPersonalColumns: [
        {
          field: "name",
          label: "Nombre",
          sortable: true,
          searchable: true,
          percentage: false,
        },
        {
          field: "grandTotal",
          label: "Gran total ventas",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantitySales",
          label: "Total en ventas",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalSales",
          label: "Cantidad de ventas",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityClarification",
          label: "Total en clarificaciones",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalClarification",
          label: "Cantidad de clarificaciones",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityCreditNotes",
          label: "Total en notas de crédito",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalCreditNotes",
          label: "Cantidad de notas de crédito",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantitySalesCancellations",
          label: "Total en ventas cancelada",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalSalesCancellation",
          label: "Cantidad de ventas canceladas",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityPurchases",
          label: "Total en compras",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalPurchases",
          label: "Cantidad de compras",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityDeliveries",
          label: "Total en envios de material",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalDeliveries",
          label: "Cantidad de envios de material",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityPayments",
          label: "Total en pagos",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalPayments",
          label: "Cantidad de pagos",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityUtilityPercentage",
          label: "Porcentaje de utilidad promedio sobre compra",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: true,
        },
        {
          field: "quantityUtilityPercentageSales",
          label: "Porcentaje de utilidad promedio sobre venta",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: true,
        },
      ],
      perPage: 25,
      currentPage: 1,
      salesPurchasesManagers: [],
      salesPurchasesManagersColumns: [
        {
          field: "name",
          label: "Nombre",
          sortable: true,
          searchable: true,
          percentage: false,
        },
        {
          field: "grandTotal",
          label: "Gran total ventas",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantitySales",
          label: "Total en ventas",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalSales",
          label: "Cantidad de ventas",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityClarification",
          label: "Total en clarificaciones",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalClarification",
          label: "Cantidad de clarificaciones",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityCreditNotes",
          label: "Total en notas de crédito",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalCreditNotes",
          label: "Cantidad de notas de crédito",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantitySalesCancellations",
          label: "Total en ventas cancelada",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalSalesCancellation",
          label: "Cantidad de ventas canceladas",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityPurchases",
          label: "Total en compras",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalPurchases",
          label: "Cantidad de compras",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityDeliveries",
          label: "Total en envios de material",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalDeliveries",
          label: "Cantidad de envios de material",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "quantityPayments",
          label: "Total en pagos",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
          percentage: false,
        },
        {
          field: "totalPayments",
          label: "Cantidad de pagos",
          numeric: true,
          money: false,
          sortable: true,
          searchable: false,
          percentage: false,
        },
      ],
      perPageManagers: 25,
      currentPageManagers: 1,
    };
  },
  methods: {
    async getAllIncomesInformation() {
      try {
        let response = await this.$store.dispatch("GETPERSONALSALESPURCHASES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
        });
        if (response && response.salesPurchasesPersonal) {
          this.salesPurchasesPersonal = response.salesPurchasesPersonal.map(
            (singlePersonal) => {
              const singlePersonalWithProps = { ...singlePersonal };

              singlePersonalWithProps.grandTotal =
                singlePersonal.quantitySales -
                singlePersonal.quantityCreditNotes -
                singlePersonal.quantityClarification -
                singlePersonal.quantitySalesCancellations;

              return singlePersonalWithProps;
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllIncomesManagersInformation() {
      try {
        let response = await this.$store.dispatch("GETMANAGERSSALESPURCHASES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouse: this.filterWarehouse,
        });
        if (response && response.salesPurchasesManagers) {
          this.salesPurchasesManagers = response.salesPurchasesManagers.map(
            (singleManager) => {
              const singleManagerWithProps = { ...singleManager };

              singleManagerWithProps.grandTotal =
                singleManager.quantitySales -
                singleManager.quantityCreditNotes -
                singleManager.quantityClarification -
                singleManager.quantitySalesCancellations;

              return singleManagerWithProps;
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        await this.getAllIncomesInformation();
        await this.getAllIncomesManagersInformation();
      }
    },
  },
  created() {
    moment.locale("es");
  },
  mounted() {
    this.getAllIncomesInformation();
    this.getAllIncomesManagersInformation();
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Bank/style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
@import "../../Global/style/InitialDashboard.css";
</style>
